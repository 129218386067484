import { QRCodeCanvas } from 'qrcode.react';
import { NoMobileDeviceWrapper } from '@layouts/NoMobileDeviceWrapper';
import { localizedString } from '@languages';
import { getTransactionToken } from '@lib/utils/transactionToken';

export const QRVerificationPage = () => {
  const verificationUrl = `${window.location.origin}/${getTransactionToken()}`;

  return (
    <NoMobileDeviceWrapper>
      <p>
        <b>{localizedString('qrVerification.useMobileDevice')}</b>
        <br />
        <br />
        {localizedString('qrVerification.youNeedToScanQR')}
        <br />
        <br />
        <QRCodeCanvas value={verificationUrl} size={256} />,
      </p>
    </NoMobileDeviceWrapper>
  );
};
